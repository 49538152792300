<template>
<div v-show="show">
    <v-container>
        <h1 class="mb-3">休業日の設定</h1>
        <p>設定を変更後「保存する」をクリックしてください</p>
        <div class="holiday_box  relative-center">
            
            <v-card class="pa-6 mb-6">
                <h2 class="mb-2">休業日（曜日指定）</h2>
                <p>曜日と、第何週かを選択してください</p>
                <div class="text-left" v-for="(h,i) in holiday" :key="i">
                    <hr class="mb-6" v-if="i>0" />
                    <v-row>
                        <v-col cols="5" xl="3" lg="3" md="3" sm="3">
                            <v-select :items="weeks" item-value="id" item-text="name" outlined label="曜日" class="week" v-model="h.week" />
                        </v-col>
                        <v-col cols="7" xl="9" lg="9" md="9" sm="9">
                            <v-checkbox label="第1" color="indigo" hide-details v-model="h.one" class="float-left ma-2"></v-checkbox>
                            <v-checkbox label="第2" color="indigo" hide-details v-model="h.two" class="float-left ma-2"></v-checkbox>
                            <v-checkbox label="第3" color="indigo" hide-details v-model="h.three" class="float-left ma-2"></v-checkbox>
                            <v-checkbox label="第4" color="indigo" hide-details v-model="h.four" class="float-left ma-2"></v-checkbox>
                            <v-checkbox label="第5" color="indigo" hide-details v-model="h.five" class="float-left ma-2"></v-checkbox>
                            <v-icon class="float-right relative t-14 f-11" @click="trashHoliday(i)">fas fa-trash-alt</v-icon>
                            <div class="clear" />
                        </v-col>
                    </v-row>
                </div>
                <div class="text-left">
                    <v-btn color="#5D4037" dark large class="f-11" @click="plusHoliday()" v-show="holiday.length<6">
                        <v-icon class="f-10 pr-1">fas fa-plus</v-icon>曜日の追加
                    </v-btn>
                </div>
            </v-card>
            <v-card class="pa-6 mb-6">
                <h2 class="mb-2">休業日（期間指定）</h2>
                <p>開始日と終了日を選択してください</p>
                <div v-for="(o,i) in obon" :key="i">
                    <hr class="mb-6" v-if="i>0" />
                    <div class="bold float-left mb-2 f-brown">【{{i+1}}】</div>
                    <v-icon class="float-right f-11" @click="trashObon(i)">fas fa-trash-alt</v-icon>
                    <div class="clear" />
                    <v-row>
                       <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <div class=" text-left">開始日</div>
                            <v-select :items="months" outlined v-model="o.startM" class="day_select float-left" />
                            <div class="relative float-left ml-1 mr-3 t-30">月</div>
                            <v-select :items="days1[i]" outlined v-model="o.startD" class="day_select float-left" @click="changeStartDay(i)" />
                            <div class="relative float-left ml-1 t-30">日</div>
                            <div class="clear" />
                        </v-col>
                         <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <div class=" text-left">終了日</div>
                            <v-select :items="months" outlined v-model="o.endM" class="day_select float-left" />
                            <div class="relative float-left ml-1 mr-3 t-30">月</div>
                            <v-select :items="days2[i]" outlined v-model="o.endD" class="day_select float-left" @click="changeEndDay(i)" />
                            <div class="relative float-left ml-1 t-30">日</div>
                            <div class="clear" />
                        </v-col>
                    </v-row>
                </div>
                <div class="text-left">
                    <v-btn color="#5D4037" dark large class="f-11" @click="plusObon()" v-show="obon.length<10">
                        <v-icon class="f-10 pr-1">fas fa-plus</v-icon>期間の追加
                    </v-btn>
                </div>
            </v-card>
        </div>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <Snackbar ref="snack" />
</div>
</template>

<script>
import axios from "axios"
import Snackbar from "@/components/snackbar.vue"

export default {

    data() {
        return {
            show: false,
            weeks: [{
                    id: 0,
                    name: "日"
                },
                {
                    id: 1,
                    name: "月"
                },
                {
                    id: 2,
                    name: "火"
                },
                {
                    id: 3,
                    name: "水"
                },
                {
                    id: 4,
                    name: "木"
                },
                {
                    id: 5,
                    name: "金"
                },
                {
                    id: 6,
                    name: "土"
                },
            ],
            holiday: [],
            months: [],
            days1: [],
            days2: [],
            obon: [],
        }
    },
    computed: {
        User() {
            return this.$store.getters['user/user']
        },
        Holiday() {
            return this.$store.getters['user/holiday']
        },
        Obon() {
            return this.$store.getters['user/obon']
        },
    },
    components: {
        Snackbar,
    },
    async mounted() {

        this.holiday = this.Holiday
        this.obon = this.Obon
        let months = []
        for (let i = 1; i <= 12; i++) {
            months[i - 1] = i
        }

        let set = []
        for (let i = 1; i <= 31; i++) {
            set[i - 1] = i
        }
        let days = []
        for (let a = 0; a < this.obon.length; a++) {
            days[a] = set
        }

        this.months = months
        this.days1 = days
        this.days2 = days
        this.show = true
    },
    methods: {
        plusHoliday() {
            let holiday = this.holiday
            this.holiday = null
            holiday[holiday.length] = {}
            holiday[holiday.length - 1].userId = this.User.id
            holiday[holiday.length - 1].week = 0
            holiday[holiday.length - 1].one = 1
            holiday[holiday.length - 1].two = 1
            holiday[holiday.length - 1].three = 1
            holiday[holiday.length - 1].four = 1
            holiday[holiday.length - 1].five = 1
            this.holiday = holiday
        },
        trashHoliday(i) {
            if (!confirm("削除しますか")) {
                return false
            }
            this.holiday.splice(i, 1)
        },
        plusObon() {
            let obon = this.obon
            this.obon = null
            obon[obon.length] = {}
            obon[obon.length - 1].startM = 1
            obon[obon.length - 1].startD = 1
            obon[obon.length - 1].endM = 1
            obon[obon.length - 1].endD = 3
            this.obon = obon

            let days = []
            for (let i = 1; i <= 31; i++) {
                days[i - 1] = i
            }
            this.days1[obon.length - 1] = days
            this.days2[obon.length - 1] = days
        },
        trashObon(i) {
            if (!confirm("削除しますか")) {
                return false
            }
            this.obon.splice(i, 1)
            this.days1.splice(i, 1)
            this.days2.splice(i, 1)
        },
        async submit() {
            const data = {
                holiday: JSON.stringify(this.holiday),
                obon: JSON.stringify(this.obon),
            }
            const response = await axios.post("/user/holiday/save", data)
            if (response.data.error) {
                alert(response.data.error)
            } else if (response.data.ok) {

                await this.$store.dispatch("user/holidaySave", response.data);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            }
        },
        async changeStartDay(i) {
            const days = await this.daySet(this.obon[i].startM)

            let days1 = this.days1
            this.days1 = null
            days1[i] = days
            this.days1 = days1
        },
        async changeEndDay(i) {
            const days = await this.daySet(this.obon[i].endM)

            let days2 = this.days1
            this.days2 = null
            days2[i] = days
            this.days2 = days2
        },
        daySet(i) {
            let last = 31
            switch (i) {
                case 2:
                    last = 28
                    break
                case 4:
                case 6:
                case 9:
                case 11:
                    last = 30
                    break
            }
            let days = []
            for (let i = 1; i <= last; i++) {
                days[i - 1] = i
            }
            return days
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/holiday.scss";
</style>
